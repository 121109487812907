<template>
  <!--banner广告管理-->
  <div class="banner">
    <a-spin :spinning="spinning">
      <div class="container">
        <div class="right">
          <div class="right_select">
            年份: &nbsp;<a-date-picker
              style="width: 12%; margin-right: 15px"
              mode="year"
              :disabled-date="disabledDate"
              placeholder="请选择"
              format="YYYY"
              v-model="yearQuarter"
              :open="yearShowOne"
              @openChange="openChangeOne"
              @panelChange="panelChangeOne"/>
            备注:     <a-input-search v-model="notes" style="width: 200px;margin-right: 15px" enter-button @search="noteSearch()" />
            状态: &nbsp;<a-radio-group default-value="up" @change="statusButton($event)">
            <a-radio-button value="up">
              已上架
            </a-radio-button>
            <a-radio-button value="down">
              已下架
            </a-radio-button>
          </a-radio-group>
          </div>
          <div class="right_list">
            <a-button
              type="primary"
              style="margin-left: 0%; margin-bottom: 1%"
              @click="addOpen"
              >新增</a-button
            >
            <a-table
              :columns="tableColumns"
              :data-source="tableData"
              rowKey="id"
              :pagination="false"
            >
              <!--          图片-->
              <template slot="images" slot-scope="text, item">
                <a v-if="item.targetUrl" :href="item.targetUrl" target="_blank">
                  <img :src="item.displayUrl" style="width: 380px" />
                </a>
                <span v-else>
                  <img
                    :src="item.displayUrl"
                    style="height: 50px; width: 100px"
                  />
                </span>
              </template>

              <!--          跳转连接-->
              <span slot="link" slot-scope="text, item" v-if="item.targetUrl">
                <a :href="item.targetUrl" target="_blank">点击打开</a>
              </span>
              <!--              排序-->
              <template
                slot="sort"
                slot-scope="text, item, index"
                v-if="item.isShow === 1"
              >
                <span v-if="index === 0">
                  <a @click="homeBannerTableUpDown(item, 'down')">下移</a>
                </span>
                <span v-else-if="index === tableData.length - 1">
                  <a @click="homeBannerTableUpDown(item, 'up')">上移</a>
                </span>
                <span v-else>
                  <a @click="homeBannerTableUpDown(item, 'up')">上移</a>
                  <a
                    style="margin-left: 13px"
                    @click="homeBannerTableUpDown(item, 'down')"
                    >下移</a
                  >
                </span>
              </template>
              <!--              操作-->
              <span slot-scope="text, item" slot="operation">
                <a @click="releaseOpen(item)" v-if="item.isShow !== 0">推广</a>
                <a @click="editOpen(item)" style="margin-left: 10px">修改</a>
                <a-popconfirm
                  title="确定要下架吗?"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="homeBannerTableUpDownShelf(item.id)"
                >
                  <a v-if="item.isShow === 1" style="margin-left: 10px">下架</a>
                </a-popconfirm>
                <a-popconfirm
                  title="确定要上架吗?"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="homeBannerTableUpDownShelf(item.id)"
                >
                  <a v-if="item.isShow !== 1" style="margin-left: 10px">上架</a>
                </a-popconfirm>
                <a-popconfirm
                  title="确定要删除吗?"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="delTableData(item.id)"
                >
                  <a v-if="item.isShow !== 1" style="margin-left: 10px">删除</a>
                </a-popconfirm>
              </span>
            </a-table>
            <div
              style="
                margin-top: 28px;
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <span
                style="
                  font-size: medium;
                  color: #929292;
                  font-size: 14px;
                  bottom: 3%;
                "
              >
                共{{ pagination.total }}条记录 第 {{ pagination.pageNum }} /
                {{ Math.ceil(pagination.total / pagination.pageSize) }} 页
              </span>
              <a-pagination
                style="float: right"
                show-quick-jumper
                show-size-changer
                :page-size.sync="pagination.pageSize"
                :total="pagination.total"
                v-model="pagination.current"
                @change="pageChange"
                @showSizeChange="pageChange"
              />
            </div>

            <!------------------------------- 对话框-------------------------------------->
            <!--            新增-->
            <a-drawer
              title="新增"
              :width="480"
              :visible="addVisible"
              @close="addClose"
            >
              <a-form-model
                :model="addForm"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                ref="addFromRef"
                :rules="addFormRules"
              >
                <a-form-model-item label="列表封面图" prop="displayUrl">
                  <div class="uploadImg">
                    <input
                      style="
                        cursor: pointer;
                        width: 100px;
                        height: 100px;
                        opacity: 0;
                      "
                      accept="image/*"
                      type="file"
                      id="addIcon"
                      @change="uploadPic('addIcon')"
                      ref="addIcon"
                    />
                  </div>
                  <div class="upload_image">
                    <img
                      alt=""
                      v-if="addForm.displayUrl"
                      style="width: 320px; height: 86px"
                      :src="addForm.displayUrl"
                    />
                  </div>
                  <div style="font-size: 14px">
                    注：建议尺寸1920*520，宽度不能超过1920，大小不超过2M
                  </div>
                </a-form-model-item>
                <a-form-model-item label="关联会议" prop="type">
                  <a-radio-group v-model="addForm.type" @change="groupChange">
                    <a-radio value="not">不关联</a-radio>
                    <a-radio value="live_metting">直播会议</a-radio>
                    <a-radio value="convention">医学会议</a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item
                  v-if="addForm.type !== 'not'"
                  prop="meetingId"
                >
                  <template slot="label">
                    {{
                      addForm.type === "live_metting" ? "直播会议" : "医学会议"
                    }}
                  </template>

                  <a-select
                    show-search
                    option-filter-prop="children"
                    @popupScroll="popupScrollMeetingId"
                    @search="selectMeetingId"
                    style="width: 300px"
                    v-model="addForm.meetingId"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="item in meetList"
                      @click="selectOption(item)"
                      :value="item.id"
                    >
                      {{ item.title }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item
                  label="所属平台"
                  prop="platform"
                  v-show="addForm.type === 'not'"
                >
                  <a-select
                    :default-value="subordinateList[0].domain_code"
                    style="width: 120px"
                    @change="platformChange"
                  >
                    <a-select-option
                      v-for="item in subordinateList"
                      :value="item.domain_code"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="跳转链接" prop="targetUrl">
                  <a-textarea
                    placeholder="请输入"
                    v-model="addForm.targetUrl"
                    style="width: 100%; height: 80px"
                  />
                </a-form-model-item>
                <a-form-model-item
                  style="margin-bottom: 50px"
                  label="备注"
                  prop="notes"
                >
                  <a-textarea
                    placeholder="请输入"
                    v-model="addForm.notes"
                    style="min-height: 150px; width: 100%"
                  />
                </a-form-model-item>
                <div
                  :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1,
                  }"
                >
                  <a-button @click="addClose"> 取消 </a-button>
                  <a-button
                    style="margin-left: 15px"
                    type="primary"
                    @click="addSubmit"
                  >
                    确定
                  </a-button>
                </div>
              </a-form-model>
            </a-drawer>

            <!--            修改-->
            <a-drawer
              title="修改"
              :width="480"
              :visible="editVisible"
              @close="editClose"
            >
              <a-form-model
                ref="editForm"
                :model="editForm"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                :rules="addFormRules"
              >
                <a-form-model-item label="列表封面图" prop="displayUrl">
                  <div class="uploadImg">
                    <input
                      style="
                        cursor: pointer;
                        width: 100px;
                        height: 100px;
                        opacity: 0;
                      "
                      accept="image/*"
                      type="file"
                      id="editIcon"
                      @change="uploadPic('editIcon')"
                      ref="editIcon"
                    />
                  </div>
                  <div class="upload_image">
                    <img
                      alt=""
                      v-if="editForm.displayUrl"
                      style="width: 320px; height: 86px"
                      :src="editForm.displayUrl"
                    />
                  </div>
                  <span style="font-size: 14px"
                    >注：建议尺寸1920*520，宽度不能超过1920，大小不超过2M</span
                  >
                </a-form-model-item>
                <a-form-model-item label="关联会议" prop="type">
                  <a-radio-group
                    v-model="editForm.type"
                    @change="groupChangeEdit"
                  >
                    <a-radio value="not">不关联</a-radio>
                    <a-radio value="live_metting">直播会议</a-radio>
                    <a-radio value="convention">医学会议</a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item
                  v-if="editForm.type !== 'not'"
                  prop="meetingId"
                >
                  <template slot="label">
                    <span
                      >{{
                        editForm.type === "live_metting"
                          ? "直播会议"
                          : "医学会议"
                      }}
                      <a-tooltip placement="top">
                        <template slot="title">
                          <span>请输入关键字进行搜索</span>
                        </template>
                        <a-icon type="exclamation-circle" />
                      </a-tooltip>
                    </span>
                  </template>
                  <a-select
                    show-search
                    option-filter-prop="children"
                    @popupScroll="popupScrollMeetingId"
                    @search="selectMeetingId"
                    style="width: 300px; margin-left: 5px"
                    @change="selectMeet"
                    v-model="editForm.meetingId"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="item in meetList"
                      @click="selectOption(item)"
                      :value="item.id"
                    >
                      {{ item.title }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item
                  label="所属平台"
                  prop="platform"
                  v-show="editForm.type === 'not'"
                >
                  <a-select
                    v-model="editForm.domainCode"
                    style="width: 120px"
                    @change="editPlatformChange"
                  >
                    <a-select-option
                      v-for="item in subordinateList"
                      :value="item.domain_code"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="跳转链接" prop="targetUrl">
                  <a-textarea
                    placeholder="请输入"
                    v-model="editForm.targetUrl"
                    style="width: 100%; height: 80px"
                  />
                </a-form-model-item>
                <a-form-model-item
                  style="margin-bottom: 50px"
                  label="备注"
                  prop="notes"
                >
                  <a-textarea
                    placeholder="请输入"
                    v-model="editForm.notes"
                    style="min-height: 150px; width: 100%; height: 80px"
                  />
                </a-form-model-item>
                <div
                  :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1,
                  }"
                >
                  <a-button @click="editClose"> 取消 </a-button>
                  <a-button
                    style="margin-left: 15px"
                    type="primary"
                    @click="editSubmit"
                  >
                    确定
                  </a-button>
                </div>
              </a-form-model>
            </a-drawer>
          </div>
        </div>
        <!--------------------发布-------------------->
        <a-drawer
          title="推广"
          :width="480"
          :visible="releaseVisible"
          @close="releaseClose"
        >
          <a-spin :spinning="releaseSpinning">
            <a-table
              :bordered="true"
              rowKey="code"
              :pagination="false"
              :columns="webColumns"
              :data-source="webAppData"
              style="margin-bottom: 20px"
            >
              <template
                slot-scope="text, item, index"
                slot="communityAppStatus"
              >
                <a-switch
                  v-model="item.status"
                  :checkedChildren="'是'"
                  :unCheckedChildren="'否'"
                  @change="releaseWebStatus($event, item)"
                  default-checked
                />
              </template>
            </a-table>
            <a-table
              :bordered="true"
              rowKey="code"
              :pagination="false"
              :columns="releaseMinaColumns"
              :data-source="releaseMinaData"
              style="margin-bottom: 20px"
            >
              <template
                slot-scope="text, item, index"
                slot="communityAppStatus"
              >
                <a-switch
                  v-model="item.dataStatus"
                  :checkedChildren="'是'"
                  :unCheckedChildren="'否'"
                  @change="releaseMinaStatus($event, item)"
                  default-checked
                />
              </template>
            </a-table>
            <a-table
              :bordered="true"
              rowKey="code"
              :pagination="false"
              :columns="releaseAppColumns"
              :data-source="releaseAppData"
            >
              <template
                slot-scope="text, item, index"
                slot="communityAppStatus"
              >
                <a-switch
                  v-model="item.dataStatus"
                  :checkedChildren="'是'"
                  :unCheckedChildren="'否'"
                  @change="releaseStatus($event, item)"
                  default-checked
                />
              </template>
            </a-table>
            <!-- <a-table :bordered="true" rowKey="name" style="margin-top: 40px;margin-bottom: 40px" :pagination="false"
                     :columns="releaseCommunityColumns" :data-source="releaseCommunityData">
              <template slot-scope="text,item,index" slot="communityColumnsStatus">
                <a-switch v-model="item.status" :checkedChildren="'是'" :unCheckedChildren="'否'"
                          @change="releaseStatus($event, item)" default-checked/>
              </template>
            </a-table>
            <a-table :bordered="true" rowKey="name" :pagination="false" :columns="releaseOtherColumns"
                     :data-source="releaseOtherData">
              <template slot-scope="text,item,index" slot="communityOtherStatus">
                <a-switch v-model="item.status" :checkedChildren="'是'" :unCheckedChildren="'否'"
                          @change="releaseStatus($event, item)" default-checked/>
              </template>
            </a-table> -->
          </a-spin>
        </a-drawer>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {
  DelHomeBannerCloseExtension,
  getHomeBanner,
  getHomeBannerExtension,
  getHomeBannerIndexExtension,
  postHomeBannerAdd,
  postHomeBannerOpenExtension,
  putHomeBannerDel,
  putHomeBannerEdit,
  putHomeBannerUpDown,
  putHomeBannerUpDownShelf,
  postBannerRecommend,
  getBannerType,
  delBannerType,
} from "@/service/systemApi";
import { update } from "@/utils/update";
import moment from "moment/moment";
import { getColumnVodsList } from "@/service/MedicalConference_y";
import { getSchool } from "@/service/school_api";
import { getLiveMeetList } from "@/service/liveMeet_api";
import { CaseLiveNew } from "@/service/MedicalConference";
import { subordinateList } from "@/utils/subordinateList";

export default {
  name: "homeBannerManagement",
  data() {
    // let validateRange = (rule, value, callback) => { // 链接格式校验正则表达式
    //   let status = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/.test(value)
    //   if (!value || value === '') {
    //     callback(new Error('请输入链接'));
    //   }else {
    //     if (!status) {
    //       callback(new Error('请输入正确的链接格式'));
    //     }else {
    //       callback();
    //     }
    //   }
    // };
    return {
      pagination: {
        //存储大table的分页
        total: 0,
        current: 1,
        pageSize: 10,
        pageNum: 1,
      },
      yearQuarter: moment(),
      yearShowOne: false,
      releaseSpinning: false,
      subordinateList: subordinateList(),
      spinning: false,
      tableColumns: [
        {
          title: "图片",
          dataIndex: "src",
          scopedSlots: { customRender: "images" },
        },
        {
          title: '备注',
          dataIndex: 'notes'
        },
        {
          title: "创建时间",
          dataIndex: "createdTime",
        },
        {
          title: '排序',
          width: '15%',
          align: 'left',
          scopedSlots: {customRender: 'sort'}
        },
        {
          title: "操作",
          width: "15%",
          align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ],
      releaseAppColumns: [
        {
          title: "APP",
          width: "50%",
          dataIndex: "title",
        },
        {
          title: "状态",
          scopedSlots: { customRender: "communityAppStatus" },
        },
      ],
      releaseMinaColumns: [
        {
          title: "小程序",
          width: "50%",
          dataIndex: "title",
        },
        {
          title: "状态",
          scopedSlots: { customRender: "communityAppStatus" },
        },
      ],
      releaseAppData: [],
      releaseMinaData: [],
      webAppData: [
        {
          id: "1",
          title: "首页",
          code: "YUN_ICU",
          type: "bannerCommunity",
          status: false,
        },
      ],
      webColumns: [
        {
          title: "官网",
          width: "50%",
          dataIndex: "title",
        },
        {
          title: "状态",
          scopedSlots: { customRender: "communityAppStatus" },
        },
      ],
      releaseCommunityColumns: [
        {
          title: "社区",
          width: "50%",
          dataIndex: "name",
        },
        {
          title: "状态",
          scopedSlots: { customRender: "communityColumnsStatus" },
        },
      ],
      releaseCommunityData: [
        {
          id: "1",
          name: "首页",
          code: "sq_index",
          type: "bannerCommunity",
          status: true,
        },
        {
          id: "2",
          code: "sq_html",
          type: "bannerCommunity",
          name: "社区内容分享页",
          status: true,
        },
      ],
      BannerId: "",
      releaseOtherColumns: [
        {
          title: "其他",
          width: "50%",
          dataIndex: "name",
        },
        {
          title: "状态",
          scopedSlots: { customRender: "communityOtherStatus" },
        },
      ],
      releaseOtherData: [
        {
          id: "1",
          name: "个人中心首页",
          code: "my_index",
          type: "bannerMy",
          status: false,
        },
      ],
      tableData: [],
      releaseVisible: false,
      addVisible: false,
      editVisible: false,

      meetList: [], //医学/直播会议列表
      meetPageNo: 1,
      meettitle: "",

      addForm: {
        displayUrl: "",
        type: "not", //类型 直播会议：live_metting 医学会议：convention 不关联:not
        meetingId: undefined,
        domainCode: "", // 所属平台
        notes: "", // 备注
      },
      editForm: {
        displayUrl: "",
        type: "not", //类型 直播会议：live_metting 医学会议：convention 不关联:not
        meetingId: undefined,
        domainCode: "",
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      notes: "",
      addFormRules: {
        displayUrl: [
          { required: true, message: "请上传图标", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择", trigger: "change" }],
        meetingId: [{ required: true, message: "请选择", trigger: "change" }],
        targetUrl: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      status: "up",
    };
  },
  mounted() {
    this.$store.dispatch("setManageHeaderTitle", "Banner广告管理");
    this.getHomeBannerTableData();
    this.getAppIndexBanner();
    this.getMinaIndexBanner();
  },
  methods: {
    // 分页查询
    pageChange(page_no, page_size) {
      this.pagination.pageNum = page_no;
      this.pagination.pageSize = page_size;
      this.getHomeBannerTableData();
    },
    // 删除table列表
    async delTableData(id) {
      const response = await putHomeBannerDel(id);
      if (response.code === 0) {
        this.$message.success("删除成功");
        await this.getHomeBannerTableData();
      } else {
        this.$message.warning(response.message);
      }
    },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearQuarter = value;
      this.yearShowOne = false;
      this.getHomeBannerTableData();
    },
    async uploadPic(type) {
      // 图片上传
      let inputDOM = this.$refs[type].files[0];
      let reader = new FileReader(); // 读取文件对象
      reader.readAsDataURL(inputDOM); //读取文件并将文件以URL的形式保存在result属性中 base64格式
      reader.onload = (e) => {
        let result = e.target.result; // 获取base64格式图片
        const image = new Image(); // 创建图片对象
        image.onerror = (e) => {
          this.$message.warning("图片加载失败，请联系管理员");
        };
        image.src = result; // 将生成的base64格式作为图片地址
        image.onload = async () => {
          if (inputDOM.size > 1048576 * 2) {
            this.$message.warning("图片大小不能超过2M");
            return;
          }
          if (image.width > 1920) {
            this.$message.warning("图片宽度不能超过1920");
            return;
          }
          const response = await update(inputDOM);
          if (response.code === 0) {
            if (type === "addIcon") {
              this.addForm.displayUrl = response.data.url;
              document.getElementById("addIcon").value = null;
            } else {
              this.editForm.displayUrl = response.data.url;
              document.getElementById("editIcon").value = null;
            }
            this.$message.success("图片上传成功！");
          } else {
            this.$message.error(response.message);
          }
        };
      };

      // if (file !== undefined) {
      //   if (file.size < 1024000) { // 限制大小
      //
      //   } else {
      //     this.$message.error("请上传小于500kb的图片")
      //   }
      // }
    },
    async getAppIndexBanner() {
      // 获取发布抽屉中APP首页栏目中的栏目数据
      // const response = await getHomeBannerIndexExtension('AD')
      // let data = []
      // if (response.code === 0) {
      //   response.data.forEach(item => {
      //     if (item.status === 'OPEN') { // 把接口返回的数据中状态为 OPEN 的值提取出来
      //       data.push(item) // 提取拼接指定状态的数据
      //     }
      //   })
      //   this.releaseAppData = data
      // } else {
      //   this.$message.warning(response.message)
      // }
      const response = await getColumnVodsList();
      if (response.code === 0) {
        this.releaseAppData = response.data;
        this.releaseAppData.unshift(
          {
            id: "1",
            title: "社区首页",
            code: "sq_index",
            type: "bannerCommunity",
            status: true,
          },
          {
            id: "2",
            code: "sq_html",
            type: "bannerCommunity",
            title: "社区内容分享页",
            status: true,
          },
          {
            id: "1",
            title: "个人中心首页",
            code: "my_index",
            type: "bannerMy",
            status: false,
          }
        );
      } else {
        this.$message.warning(response.message);
      }
    },
    async getMinaIndexBanner() {
      // 获取发布抽屉中小程序首页栏目中的栏目数据
      const response = await getColumnVodsList();
      if (response.code === 0) {
        this.releaseMinaData = response.data;
        this.releaseMinaData.forEach((item, index) => {
          if (item.title == "云ICU") {
            this.releaseMinaData[index].title = "推荐页（首页）";
          }
        });
        //   this.releaseMinaData.unshift(
        //   {
        //     id: '1',
        //     title: '首页',
        //     code: 'sq_index',
        //     type: 'bannerCommunity',
        //     status: true
        //   },
        //   {
        //     id: '2',
        //     code: 'sq_html',
        //     type: 'bannerCommunity',
        //     title: '社区内容分享页',
        //     status: true
        //   },
        //   {
        //     id: '1',
        //     title: '个人中心首页',
        //     code: 'my_index',
        //     type: 'bannerMy',
        //     status: false
        //   }
        // )
      } else {
        this.$message.warning(response.message);
      }
    },
    disabledDate(current) {
      // 限制日期选择
      return (
        current && moment(current).format("YYYY") > moment().format("YYYY")
      );
    },
    addClose() {
      // 新增关闭
      this.addVisible = false;
      this.meettitle = "";
      this.meetList = [];
      this.meetPageNo = 1;
      this.addForm = {
        displayUrl: "",
        type: "not",
        meetingId: undefined,
      };
    },
    addOpen() {
      // 新增打开
      this.addVisible = true;
      this.addForm.domainCode = this.subordinateList[0].domain_code;
    },
    async addSubmit() {
      // 新增提交保存
      this.$refs.addFromRef.validate(async (valid) => {
        if (valid) {
          this.spinning = true;
          let data = {
            displayUrl: this.addForm.displayUrl,
            targetUrl: this.addForm.targetUrl,
            type: this.addForm.type,
            meetingId: this.addForm.meetingId,
            domainCode: this.addForm.domainCode,
            notes: this.addForm.notes,
          };
          const response = await postHomeBannerAdd(data);
          if (response.code === 0) {
            this.$message.success("新增成功");
            await this.getHomeBannerTableData();
            // this.addVisible = false
            this.addClose();
            this.addForm = {
              displayUrl: "",
              type: "not",
              meetingId: undefined,
            };
            this.$refs.addIcon.value = null;
          } else {
            this.$message.warning(response.message);
          }
          this.spinning = false;
        } else {
          this.$message.error("请填写全部表单");
        }
      });
    },
    editClose() {
      // 修改关闭
      this.editVisible = false;
      this.meettitle = "";
      this.meetList = [];
      this.meetPageNo = 1;
      this.editForm = {
        displayUrl: "",
        type: "not",
        meetingId: undefined,
      };
    },
    selectMeet(value) {
      this.editForm.meetingId = value;
    },
    async editOpen(row) {
      // 修改打开
      let time;
      if (this.notes === undefined || this.notes === "") {
        this.notes = "";
      }
      time = "";
      const response = await getHomeBanner("", row.id, time, this.notes);
      if (response.code === 0) {
        // this.editForm = response.data[0]
        //   if( this.editForm.type === undefined){
        //     this.editForm.type = 'not'
        //   }
        //  if( this.editForm.meetingId === undefined){
        //    this.$set(this.editForm,"meetingId",undefined)
        //   }
        this.editForm = response.data[0];
        if (!this.editForm.type) {
          // this.editForm.type = 'not'
          this.$set(this.editForm, "type", "not");
        }
        if (!this.editForm.meetingId) {
          this.$set(this.editForm, "meetingId", undefined);
        }
        if (this.editForm.type !== "not") {
          await this.getMeetList(this.editForm.type, this.editForm.meetingId);
        }
      } else {
        this.$message.warning(response.message);
      }
      this.editVisible = true;
    },
    async editSubmit() {
      // 修改提交
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          this.spinning = true;
          let data = {
            id: this.editForm.id,
            displayUrl: this.editForm.displayUrl,
            targetUrl: this.editForm.targetUrl,
            type: this.editForm.type,
            meetingId: this.editForm.meetingId,
            domainCode: this.editForm.domainCode,
            notes: this.editForm.notes,
          };
          const response = await putHomeBannerEdit(data);
          if (response.code === 0) {
            this.$message.success("修改成功");
            await this.getHomeBannerTableData();
            this.editVisible = false;
            this.$refs.editIcon.value = null;
          } else {
            this.$message.warning(response.message);
          }
          this.spinning = false;
        } else {
          this.$message.warning("请填写全部表单项");
        }
      });
    },
    noteSearch(){
      this.pagination.pageNum = 1
      this.pagination.pageSize = 10
      this.getHomeBannerTableData()
    },
    async getHomeBannerTableData() { // 获取 table 列表
      this.spinning = true
      let time
      if (this.yearQuarter === null || this.yearQuarter === undefined) {
        time = "";
      } else {
        time = moment(this.yearQuarter).format("YYYY");
      }
      const response = await getHomeBanner(this.status, '', time, this.notes,this.pagination.pageNum,this.pagination.pageSize)
      if (response.code === 0) {
        this.tableData = response.data
        this.pagination.total = response.count
      } else {
        this.$message.warning(response.message);
      }
      this.spinning = false;
    },
    async releaseOpen(row) {
      // 发布抽屉打开
      for (let i = 0; i < this.releaseAppData.length; i++) {
        this.releaseAppData[i].dataStatus = false;
        this.$set(this.releaseAppData, i, this.releaseAppData[i]); // 使用 $set 强制 vue 再次渲染数据
      }
      this.spinning = true;
      const response = await getHomeBannerExtension(row.id); // 调用查询栏目状态接口
      if (response.code === 0) {
        this.BannerId = row.id; // 获取要修改的 bannerId 保存到外部
        this.releaseCommunityData[0].status = response.data.some((v) => {
          return v.code === "sq_index";
        }); // 判断三个固定栏目的值 是否在接口返回的数据中，存在 即状态开启 不存在则 状态关闭
        this.releaseCommunityData[1].status = response.data.some((v) => {
          return v.code === "sq_html";
        });
        this.releaseOtherData[0].status = response.data.some((v) => {
          return v.code === "my_index";
        });
        response.data.forEach((item) => {
          for (let i = 0; i < this.releaseAppData.length; i++) {
            if (this.releaseAppData[i].code === item.code && item.code) {
              this.releaseAppData[i].dataStatus = true;
              this.$set(this.releaseAppData, i, this.releaseAppData[i]); // 使用 $set 强制 vue 再次渲染数据
            }
          }
        });
        // this.releaseAppData.forEach((a, index) => { // APP首页的栏目值的状态
        //   let status = response.data.some(b => {
        //     return a.code === b.code
        //   })
        //   a.dataStatus = status // 由于数组没有get、set方法，所以在用循环操作数组的情况下，即便使用了双向绑定，也会存在数据无法更新的问题
        //   this.$set(this.releaseAppData, index, a) // 使用 $set 强制 vue 再次渲染数据
        // })
        this.releaseVisible = true;
      }
      // 查询官网和小程序的推广状态
      this.getWebType();
      this.getMinaType();
      this.spinning = false;
    },
    // 官网状态
    async getWebType() {
      const response = await getBannerType("web", this.BannerId); // 调用查询栏目状态接口
      if (response.code === 0) {
        // this.releaseVisible = true
        // console.log("状态",response)
        if (response.data.length !== 0) {
          this.webAppData[0].status = true;
        } else {
          this.webAppData[0].status = false;
        }
      } else {
        this.$message.warning(response.message);
      }
    },
    // 小程序状态
    async getMinaType() {
      // 重置releaseAppData的数据
      for (let i = 0; i < this.releaseMinaData.length; i++) {
        this.releaseMinaData[i].dataStatus = false; //先全部关闭再重新赋值
        this.$set(this.releaseMinaData, i, this.releaseMinaData[i]); // 使用 $set 强制 vue 再次渲染数据
      }
      const response = await getBannerType("mina", this.BannerId); // 调用查询栏目状态接口
      if (response.code === 0) {
        // this.releaseVisible = true
        response.data.forEach((item) => {
          for (let i = 0; i < this.releaseMinaData.length; i++) {
            if (this.releaseMinaData[i].code === item.advCode && item.advCode) {
              this.releaseMinaData[i].dataStatus = true;
              this.$set(this.releaseMinaData, i, this.releaseMinaData[i]); // 使用 $set 强制 vue 再次渲染数据
            }
          }
        });
      } else {
        this.$message.warning(response.message);
      }
    },
    releaseClose() {
      // 发布抽屉关闭
      // this.releaseMinaData = []
      this.releaseVisible = false;
    },
    async releaseMinaStatus(status, row) {
      if (status) {
        // （开启）
        let data =
          // 定义传参的格式、类型和值
          {
            bannerId: this.BannerId,
            advCode: row.code,
            bannerLocation: "mina",
          };
        const response = await postBannerRecommend(data); // 调用开启接口
        if (response.code === 0) {
          // 判断状态
          this.$message.success("推广成功！");
          await this.getHomeBannerTableData();
          this.getMinaType();
        } else {
          this.$message.warning(response.message);
        }
      } else {
        const response = await delBannerType("mina", row.code, this.BannerId); // 调用开启接口
        if (response.code === 0) {
          // 判断状态
          this.$message.success("关闭成功！");
          await this.getHomeBannerTableData();
          this.getMinaType();
        } else {
          this.$message.warning(response.message);
        }
      }
    },
    async releaseStatus(status, row) {
      // 发布状态点击方法，开启关闭发布
      this.releaseSpinning = true;
      let type = row.type; // 判断当前操作的是哪个栏目的状态
      if (type === undefined) type = "bannerApp"; // APP首页中的栏目不带有 type 属性 在此进行区分
      if (status) {
        // （开启）判断当前是开启操作还是关闭操作
        let data = [
          // 定义传参的格式、类型和值
          {
            bannerId: this.BannerId,
            advCode: row.code,
          },
        ];
        const response = await postHomeBannerOpenExtension(data); // 调用开启接口
        if (response.code === 0) {
          // 判断状态
          this.$message.success("推广成功！");
          await this.getHomeBannerTableData();
        } else {
          this.$message.warning(response.message);
        }
      } else {
        // （关闭）判断当前是开启操作还是关闭操作
        let data = {
          // 定义传参的格式、类型和值
          code: row.code,
          type: type,
        };
        const response = await DelHomeBannerCloseExtension(this.BannerId, data); // 调用关闭接口
        if (response.code === 0) {
          this.$message.success("关闭成功！");
          await this.getHomeBannerTableData();
        } else {
          this.$message.warning(response.message);
        }
      }
      // 更新双向绑定的数据源
      this.releaseAppData.forEach((a, index) => {
        if (a.code === row.code) {
          this.$set(this.releaseAppData, index, a);
          return;
        }
      });
      this.releaseSpinning = false;
    },
    async releaseWebStatus(status, row) {
      if (status) {
        // （开启）
        let data =
          // 定义传参的格式、类型和值
          {
            bannerId: this.BannerId,
            advCode: row.code,
            bannerLocation: "web",
          };
        const response = await postBannerRecommend(data); // 调用开启接口
        if (response.code === 0) {
          // 判断状态
          this.$message.success("推广成功！");
          // await this.getHomeBannerTableData()
          this.getWebType();
        } else {
          this.$message.warning(response.message);
        }
      } else {
        const response = await delBannerType("web", row.code, this.BannerId); // 调用开启接口
        if (response.code === 0) {
          // 判断状态
          this.$message.success("关闭成功！");
          // await this.getHomeBannerTableData()
          this.getWebType();
        } else {
          this.$message.warning(response.message);
        }
      }
    },
    async homeBannerTableUpDown(row, move) {
      // 上移下移banner广告
      this.spinning = true;
      const response = await putHomeBannerUpDown(row.id, move);
      if (response.code === 0) {
        await this.getHomeBannerTableData();
      } else {
        this.$message.success("操作成功");
        this.$message.warning(response.message);
      }
      this.spinning = false;
    },
    async homeBannerTableUpDownShelf(id) {
      // 上架下架banner广告
      this.spinning = true;
      const response = await putHomeBannerUpDownShelf(id);
      if (response.code === 0) {
        this.$message.success("操作成功");
        await this.getHomeBannerTableData();
      } else {
        this.$message.warning(response.massage);
      }
      this.spinning = false;
    },
    async statusButton(e) {
      let time;
      if (this.yearQuarter === null || this.yearQuarter === undefined) {
        time = "";
      } else {
        time = moment(this.yearQuarter).format("YYYY");
      }
      this.spinning = true
      this.status = e.target.value
      const response = await getHomeBanner(this.status, '', time, this.notes,this.pagination.pageNum ,this.pagination.pageSize )
      if (response.code === 0) {
        this.tableData = response.data
        this.pagination.total = response.count
      } else {
        this.$message.warning(response.message);
      }
      this.spinning = false;
    },
    groupChange(e) {
      this.addForm.type = e.target.value;
      this.meettitle = "";
      this.meetList = [];
      this.meetPageNo = 1;
      this.addForm.meetingId = undefined;
      if (e.target.value === "not") {
        this.addForm.targetUrl = "";
      } else {
        this.getMeetList(e.target.value);
      }
    },
    groupChangeEdit(e) {
      this.editForm.type = e.target.value;
      this.meettitle = "";
      this.meetList = [];
      this.meetPageNo = 1;
      this.editForm.meetingId = undefined;
      if (e.target.value === "not") {
        this.editForm.targetUrl = "";
      } else {
        this.getMeetList(e.target.value);
      }
    },
    //获取医学会议/直播会议列表
    async getMeetList(type, id) {
      let data = {};
      let response = null;
      if (type === "live_metting") {
        //直播会议
        data = {
          title: this.meettitle,
          pageNo: this.meetPageNo,
          id: id,
        };
        response = await getLiveMeetList(data); //直播会议
      } else if (type === "convention") {
        //医学会议
        data = {
          page_no: this.meetPageNo,
          title: this.meettitle,
          id: id,
        };
        response = await CaseLiveNew(data);
      }
      if (response.code === 0) {
        let list = response.data.rows;
        for (let i = 0; i < list.length; i++) {
          //判断是医学会议还是直播会议
          if (type === "live_metting") {
            this.meetList.push({
              id: list[i].id,
              title: list[i].title,
              url: list[i].targetUrl,
              domainCode: list[i].domainCode,
            });
            // this.meetList.forEach((item)=>{
            //   if(item.id !== list[i].id){ //判断是不是已经添加过某项了
            //     this.meetList.push({
            //       id: list[i].id,
            //       title: list[i].title,
            //       url:list[i].targetUrl,
            //     })
            //   }
            // })
          } else if ("convention") {
            this.meetList.push({
              id: list[i].id,
              title: list[i].title,
              url: list[i].tempUrl,
              domainCode: list[i].domainCode,
            });

            // this.meetList.forEach((item)=>{
            //   if(item.id !== list[i].id){ //判断是不是已经添加过某项了
            //     this.meetList.push({
            //       id: list[i].id,
            //       title: list[i].title,
            //       url:list[i].tempUrl
            //     })
            //   }
            // })
          }
        }
      }
    },
    platformChange(value) {
      this.addForm.domainCode = value;
    },
    editPlatformChange(value) {
      this.editForm.domainCode = value;
    },
    selectOption(item) {
      if (this.addVisible) {
        //新增
        this.$set(this.addForm, "targetUrl", item.url);
        this.$set(this.addForm, "domainCode", item.domainCode);
      } else if (this.editVisible) {
        this.$set(this.editForm, "targetUrl", item.url);
        this.$set(this.editForm, "domainCode", item.domainCode);
      }
    },
    //滚动条监听
    popupScrollMeetingId(e) {
      const { target } = e;
      const scrollHeight = target.scrollHeight - target.scrollTop; //滑动总高度
      const clientHeight = target.clientHeight; //滑动最低高度
      if (scrollHeight < clientHeight + 2) {
        this.meetPageNo++;
        let type;
        if (this.addVisible) {
          //新增
          type = this.addForm.type;
        } else if (this.editVisible) {
          //修改
          type = this.editForm.type;
        }
        this.getMeetList(type);
      }
    },
    //鼠标搜索
    selectMeetingId(value) {
      //首先清空数组，否则会数据重复
      this.meetList = [];
      this.meettitle = value;
      this.meetPageNo = 1;
      let type;
      if (this.addVisible) {
        //新增
        type = this.addForm.type;
      } else if (this.editVisible) {
        //修改
        type = this.editForm.type;
      }
      this.getMeetList(type);
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);

  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .left {
    width: 14%;
    background: white;

    ::v-deep.ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border-right: none;
    }
  }

  .right {
    flex: 1;
    margin-left: 1%;
    background: white;
    padding: 15px;

    .right_list {
      margin-top: 1.5%;
      .Info_list {
        margin: auto;
        width: 100%;
        height: 100%;
        min-height: 549px;

        .Info_list_Template {
          border-radius: 3px;
          border: #dddddd 1px solid;
          background-color: white;
          width: 24%;
          float: left;
          height: auto;
          margin: 1%;

          .Template {
            .Template_title {
              font-size: 15px;
              font-weight: bolder;
              display: flex;
              justify-content: space-between;
              margin: 5px;
            }
          }

          .Template_btn {
            cursor: pointer;
            height: 31px;
            display: flex;
            margin: 0 auto;
            align-items: center;
            padding: 0 2%;
            background-color: rgba(247, 249, 250, 1);
            justify-content: space-between;

            .operation_btn {
              width: 40%;
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}
.right_select {
  margin: 0 -15px;
  padding: 0 15px 15px;
  border-bottom: 12px solid #f5f5f5;
}
.uploadImg {
  border: #dddddd 2px dashed;
  width: 100px;
  height: 100px;
  background-image: url("../../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  margin: 0 auto 5px;
  //float: left;
}

.upload_image {
  border: #dddddd 2px dashed;
  width: 325px;
  height: 90px;
  //float: left;
}
</style>

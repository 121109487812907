export function subordinate(){
    //  所属平台 带全部的列表
    let list = [
        {
            id:1,
            name:'全部',
            domain_code:'',
            value: '',
        },
        {
            id:2,
            name:'医驰',
            domain_code:'yichimeeting',
            value:'https://yichimeeting-mp.yichimeeting.com'
        }, {
            id:3,
            name:'云ICU',
            domain_code:'yunicu',
            value:'https://yunicu-mp.yunicu.com'
        }, {
            id:4,
            name:'IE- Learning',
            domain_code:'ie-learning',
            value:'https://ielearning-mp.ie-learning.cn'
        },{
            id:5,
            name:'APnet',
            domain_code:'aphouse',
            value:'https://apnet-mp.aphouse.cn'
        },
    ]
    return list
}
export function subordinateList(){
    //  所属平台 带全部的列表
    let list = [
        {
            id:1,
            name:'医驰',
            domain_code:'yichimeeting',
        }, {
            id:2,
            name:'云ICU',
            domain_code:'yunicu',
        }, {
            id:3,
            name:'IE- Learning',
            domain_code:'ie-learning',
        },{
            id:4,
            name:'APnet',
            domain_code:'aphouse',
        },
    ]
    return list
}


